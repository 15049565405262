"use strict";

var _interopRequireDefault = require("/var/jenkins_home/node_modules_list/test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vue = _interopRequireDefault(require("vue"));
var _SvgIcon = _interopRequireDefault(require("@/components/SvgIcon"));
// svg component

// register globally
_vue.default.component('svg-icon', _SvgIcon.default);
const req = require.context('./svg', false, /\.svg$/);
const requireAll = requireContext => requireContext.keys().map(requireContext);
requireAll(req);