"use strict";

var _interopRequireDefault = require("/var/jenkins_home/node_modules_list/test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("element-ui/lib/theme-chalk/index.css");
var _mavonEditor = _interopRequireDefault(require("mavon-editor"));
require("mavon-editor/dist/css/index.css");
var _permission = _interopRequireDefault(require("@/utils/permission"));
var _Permission = _interopRequireDefault(require("./components/Permission"));
require("./assets/styles/element-variables.module.scss");
require("./assets/styles/index.scss");
var _vueHighlightjs = _interopRequireDefault(require("vue-highlightjs"));
require("highlight.js/styles/atom-one-dark.css");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _routers = _interopRequireDefault(require("./router/routers"));
require("./assets/icons");
require("./router/index");
require("echarts-gl");
var _vueFullscreen = _interopRequireDefault(require("vue-fullscreen"));
var _vueAmap = _interopRequireDefault(require("vue-amap"));
var _settings = _interopRequireDefault(require("./settings"));
//

// 数据字典
// import dict from './components/Dict'

// 权限指令

// global css

// 代码高亮

// icon
// permission control

// 全屏插件

_vue.default.use(_vueFullscreen.default);

// 视频直播
// 引入videojs
// 支持的视频格式：
// opus:'video/ogg'
// ogv:'video/ogg'
// mp4:'video/mp4'
// mov:'video/mp4'
// m4v:'video/mp4'
// mkv:'video/x-matroska'
// m4a:'audio/mp4'
// mp3:'audio/mpeg'
// aac:'audio/aac'
// caf:'audio/x-caf'
// flac:'audio/flac'
// oga:'audio/ogg'
// wav:'audio/wav'
// m3u8:'application/x-mpegURL'
// jpg: 'image/jpeg'
// jpeg:'image/jpeg'
// gif:'image/gif'
// png:'image/png'
// svg:'image/svg+xml'
// webp:'image/webp'
// import Video from 'video.js';
// import 'video.js/dist/video-js.css';
// Vue.prototype.$video = Video;
// import hls from 'videojs-contrib-hls';
// Vue.use(hls); // 要播放hls流

// 高德地图插件

_vue.default.use(_vueAmap.default);
_vueAmap.default.initAMapApiLoader({
  key: _settings.default.mapKey,
  plugin: ['MapType', 'AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
});
_vue.default.use(_permission.default);
_vue.default.use(_vueHighlightjs.default);
_vue.default.use(_mavonEditor.default);
_vue.default.use(_Permission.default);
// Vue.use(dict)
_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'small' // set element-ui default size
});

// 引入cesium基础库
// import 'mars3d-cesium/Build/Cesium/Widgets/widgets.css'
// import * as Cesium from 'mars3d-cesium'

// // 在main.js中加入下面代码
// import 'mars3d/dist/mars3d.css'
// import * as mars3d from 'mars3d'

// // 导入mars3d插件（按需使用，需要先npm install）
// import 'mars3d-space'

// // 可以绑定下vue原型链，可以全局使用
// Vue.prototype.mars3d = mars3d

// // 在具体使用的vue等文件中
// // 直接使用 this.mars3d 进行相关调用。
// window.mars3d = mars3d

_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _routers.default,
  store: _store.default,
  render: h => h(_App.default)
});