"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      padding: "0 15px"
    },
    on: {
      click: _vm.toggleClick
    }
  }, [_c("svg", {
    staticClass: "hamburger",
    class: {
      "is-active": _vm.isActive
    },
    attrs: {
      viewBox: "0 0 1024 1024",
      xmlns: "http://www.w3.org/2000/svg",
      width: "64",
      height: "64"
    }
  }, [_c("path", {
    attrs: {
      d: "M132 112h760c11.046 0 20 8.954 20 20s-8.954 20-20 20H132c-11.046 0-20-8.954-20-20s8.954-20 20-20z m0 255h450c11.046 0 20 8.954 20 20s-8.954 20-20 20H132c-11.046 0-20-8.954-20-20s8.954-20 20-20z m0 250h450c11.046 0 20 8.954 20 20s-8.954 20-20 20H132c-11.046 0-20-8.954-20-20s8.954-20 20-20z m0 255h760c11.046 0 20 8.954 20 20s-8.954 20-20 20H132c-11.046 0-20-8.954-20-20s8.954-20 20-20z m770.689-342.071L738.407 661.94c-9.902 7.957-24.379 6.38-32.336-3.522A23 23 0 0 1 701 644.012V379.988c0-12.703 10.297-23 23-23a23 23 0 0 1 14.407 5.071l164.282 132.012c9.901 7.957 11.478 22.434 3.521 32.336a23 23 0 0 1-3.521 3.522z",
      fill: "#ffffff",
      "p-id": "5530"
    }
  })])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;