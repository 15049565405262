"use strict";

var _interopRequireDefault = require("/var/jenkins_home/node_modules_list/test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _uav = _interopRequireDefault(require("@/api/uav/uav"));
var _manage = _interopRequireDefault(require("@/api/manage/manage"));
var _flighttask = _interopRequireDefault(require("@/api/flighttask/flighttask"));
var _flighttaskrecord = _interopRequireDefault(require("@/api/flighttask/flighttaskrecord"));
var _hangar = _interopRequireDefault(require("@/api/hangar/hangar"));
var _centerPoint = _interopRequireDefault(require("@/api/centerPoint/centerPoint"));
var _leftTopBtImg = _interopRequireDefault(require("@/assets/images/control/leftTopBtImg1.png"));
var _leftTopBtImg2 = _interopRequireDefault(require("@/assets/images/control/leftTopBtImg2.png"));
var _leftTopBtImg3 = _interopRequireDefault(require("@/assets/images/control/leftTopBtImg.png"));
var _rightTopBtImg = _interopRequireDefault(require("@/assets/images/control/rightTopBtImg.png"));
var _dataBg = _interopRequireDefault(require("@/assets/images/control/data-bg1.png"));
var _dataBg2 = _interopRequireDefault(require("@/assets/images/control/data-bg2.png"));
var _dataBg3 = _interopRequireDefault(require("@/assets/images/control/data-bg3.png"));
var _airplane = _interopRequireDefault(require("@/assets/images/control/airplane.png"));
var _site = _interopRequireDefault(require("@/assets/images/site.png"));
var _rightTopBtImg2 = _interopRequireDefault(require("@/assets/images/control/rightTopBtImg1.png"));
var _rightTopBtImg3 = _interopRequireDefault(require("@/assets/images/control/rightTopBtImg2.png"));
var _rightTopBtImgNo = _interopRequireDefault(require("@/assets/images/control/rightTopBtImgNo.png"));
var _live = _interopRequireDefault(require("@/assets/images/control/live.png"));
var _backhome = _interopRequireDefault(require("@/assets/images/control/backhome.png"));
var _videoBg = _interopRequireDefault(require("@/assets/images/control/video-bg.png"));
var _videoTitle = _interopRequireDefault(require("@/assets/images/control/video-title.png"));
var _global = _interopRequireDefault(require("@/utils/global"));
var _rem = require("@/utils/rem");
var _vuex = require("vuex");
var _coordtransform = _interopRequireDefault(require("coordtransform"));
var _jswebrtcMin = _interopRequireDefault(require("@/utils/jswebrtc.min.js"));
var _default = exports.default = {
  computed: {
    ...(0, _vuex.mapGetters)(['user', 'baseWSURL'])
  },
  data() {
    return {
      // 样式
      globalWidth: _global.default.windowWidth,
      topStyle: {
        'width': 'calc(100vw - 42.856rem)',
        'font-size': '1.57rem',
        'left': '21.428rem'
      },
      controlBoxStyle: {
        'width': '21.428rem'
      },
      // ================================================================
      videoBg: _videoBg.default,
      videoTitle: _videoTitle.default,
      uavIconImg: _airplane.default,
      airportIconImg: _site.default,
      leftTopBtImg: _leftTopBtImg3.default,
      leftTopBtImg1: _leftTopBtImg.default,
      leftTopBtImg2: _leftTopBtImg2.default,
      rightTopBtImg: _rightTopBtImg.default,
      rightTopBtImg1: _rightTopBtImg2.default,
      rightTopBtImg2: _rightTopBtImg3.default,
      rightTopBtImgNo: _rightTopBtImgNo.default,
      databg1: _dataBg.default,
      databg2: _dataBg2.default,
      databg3: _dataBg3.default,
      liveImg: _live.default,
      backhomeImg: _backhome.default,
      uavId: null,
      uav: '',
      uavState: {
        flightControllerState: {
          gpsSignalLevel: 0
        }
      },
      dateStr: '时间',
      member: {},
      // center: [118.73382672155715, 31.98244469882127],
      // center: [118.89237614319788, 31.915627203281343],
      center: [118.894953, 31.912943],
      zoom: 18,
      // 标记点
      markers: [],
      // 虚线
      // polylinePath: [],
      map: null,
      plugins: [{
        pName: 'MapType',
        defaultType: 1,
        // 0代表默认，1代表卫星
        showTraffic: true,
        // 路网
        showRoad: true // 路况
      }],
      // 左侧tab
      leftTopBtnValue: 1,
      taskList: [],
      selectTaskValue: '',
      // 实时数据
      uavOSD: {
        // {"0":"待机","1":"起飞准备","2":"起飞准备完毕","3":"手动飞行","4":"自动起飞","5":"航线飞行",
        // "6":"全景拍照","7":"智能跟随","8":"ADS-B 躲避","9":"自动返航","10":"自动降落","11":"强制降落","12":"三桨叶降落","13":"升级中","14":"未连接"}
        modeCode: 0,
        modeCodeStr: '待机',
        attitudeHead: 0,
        battery: {
          capacityPercent: 0,
          returnHomePower: 0
        },
        // {"0":"A","1":"P","2":"NAV","3":"FPV","4":"FARM","5":"S","6":"F","7":"M","8":"G","9":"T"}
        gear: 1,
        gearStr: 'P',
        height: 0,
        elevation: 0,
        horizontalSpeed: 0,
        verticalSpeed: 0,
        latitude: 0,
        longitude: 0,
        positionState: {
          gpsNumber: 0,
          isFixed: 0,
          quality: 0,
          rtkNumber: 0
        },
        totalFlightDistance: 0,
        totalFlightTime: 0,
        totalFlightSorties: 0,
        // 	{"1":"正北","2":"东北","3":"东","4":"东南","5":"南","6":"西南","7":"西","8":"西北"}
        windDirection: 0,
        windSpeed: 0
      },
      onLine: 0,
      // 加载中的遮层
      loading: '',
      loadCenter: false,
      uavPoint: {
        // position: [116.4057110688, 39.8949312153]
        // position: [118.73382672155715, 31.98244469882127]
        // position: [118.89237614319788, 31.915627203281343]
        position: [0, 0]
      },
      horizontalSpeedPoint: -150,
      verticalSpeedPoint: -150,
      uavYawPoint: 0,
      uavPitchPoint: 0,
      events: {
        click: this.flyPoint
      },
      // 当前执飞中的航线线路
      routePointList: [],
      // 在地图上显示的线路
      polylinePath: [],
      interval: null,
      taskFlag: 0,
      task: {
        name: '',
        createTime: ''
      },
      loadUavTaskFlag: false,
      // 循环加载当前已有的所有轨迹点（初始化时防止数据量过大）
      timer: null,
      // 是否初始化了飞行轨迹
      loadPathFlag: false,
      // 执飞操作的加载框
      loadingFlag: null,
      // 选中的任务对象
      selectObj: null,
      // 右边tab切换
      rightTopBtnValue: 1,
      // 是否有机场  默认无机场
      airportFlag: false,
      airportOnline: 1,
      // 机场实时数据
      airportOSD: {
        // // 机库经度
        // hangarLng: '118.73382672155715',
        // // 机库纬度
        // hangarLat: '31.98244469882127',
        // // 备降点经度
        // secondLng: '118.73382672155715',
        // // 备降点纬度
        // secondLat: '31.98244469882127',

        // 118.89237614319788,31.915627203281343
        // 机库经纬度
        // latitude: '118.89237614319788',
        // longitude: '31.915627203281343',
        latitude: '0',
        longitude: '0',
        // 备降点
        alternateLandPoint: {
          // latitude: '31.804152900562947',
          // longitude: '121.0930947913965',
          latitude: '0',
          longitude: '0',
          safeLandHeight: 30,
          isConfigured: 1
        },
        // 舱外视频： 0-无，1-有
        outVideo: '0',
        // 充电状态：0-空闲，1-充电中
        chargeState: '0',
        chargeStateStr: '空闲',
        // 无人机电池信息
        droneChargeState: {
          // 电量百分比
          capacityPercent: '0',
          state: 0
        },
        // 机场状态："0":"空闲中","1":"现场调试","2":"远程调试","3":"固件升级中","4":"作业中"
        modeCode: '0',
        modeCodeStr: '空闲中',
        // 舱盖状态："0":"关闭","1":"打开","2":"半开","3":"舱盖状态异常"
        coverState: '0',
        coverStateStr: '关闭',
        // 推杆状态："0":"关闭","1":"打开","2":"半开","3":"推杆状态异常"
        putterState: '0',
        putterStateStr: '关闭',
        // 补光灯状态："0":"关闭","1":"打开"
        supplementLightState: '0',
        supplementLightStateStr: '关闭',
        // 飞机是否在舱："0":"舱外","1":"舱内"
        droneInDock: '1',
        droneInDockStr: '舱内',
        // 机场声光报警状态："0":"声光报警关闭","1":"声光报警开启"
        alarmState: '0',
        alarmStateStr: '关闭',
        // 机场累计作业次数
        jobNumber: '1',
        // 舱内温度：摄氏度
        temperature: '0',
        temperatureStr: '0',
        // 舱内湿度：%RH
        humidity: '0',
        humidityStr: '0',
        // 机场累计运行时长：秒
        accTime: '0',
        // 环境温度：摄氏度
        environmentTemperature: '0',
        environmentTemperatureStr: '0',
        // 风速：米每秒
        windSpeed: '0',
        windSpeedStr: '0',
        // 降雨量："0":"无雨","1":"小雨","2":"中雨","3":"大雨"
        rainfall: '0',
        rainfallStr: '无雨',
        // 空调模式：{"0":"空闲模式（无制冷、制热、除湿等）","1":"制冷模式","2":"制热模式","3":"除湿模式",
        //   "4":"制冷退出模式","5":"制热退出模式","6":"除湿退出模式","7":"制冷准备模式","8":"制热准备模式","9":"除湿准备模式"}
        airConditionerMode: '0',
        airModeStr: '空闲',
        emergencyStopState: 0,
        emergencyStopStateStr: '关闭'
      },
      // airportPosition: [118.73302672155715, 31.98244469882127],
      // airportPosition: [118.89237614319788, 31.915627203281343],
      airportPosition: [],
      // 无人机直播
      liveUrl: '',
      liveFlag: false,
      player: undefined,
      // 机场直播
      airportLiveUrl: '',
      airportLiveFlag: false,
      airportPlayer: undefined,
      // 飞机fpv视频（有机场时，能将飞机的云台和FPV同时打开）
      airportUavFPVLiveUrl: '',
      airportUavFPVLiveFlag: false,
      airportUavFPVPlayer: undefined,
      // 是否展示直播选择框
      liveCheckBoxVisible: false,
      // 选择的直播
      liveCheckList: [],
      // 能够选择的直播
      liveChooseList: [{
        name: '无人机云台',
        check: false
      }, {
        name: '无人机FPV',
        check: false
      }, {
        name: '机场',
        check: false
      }],
      airportStateInterval: null,
      // 是否显示无人机图标,默认显示（无机场：总是显示无人机图标；有机场：当无人机在机场5米范围内时隐藏无人机图标）
      showUavFlag: true,
      airportLoaded: false,
      airportName: '',
      // 任务航线点列表
      taskPointList: [],
      // 任务航线
      taskLinePath: [],
      offset: [-11, -11],
      airportOnline: 0,
      modeCodeObj: {
        '0': '待机',
        '1': '起飞准备',
        '2': '起飞准备完毕',
        '3': '手动飞行',
        '4': '自动起飞',
        '5': '航线飞行',
        '6': '全景拍照',
        '7': '智能跟随',
        '8': 'ADS-B 躲避',
        '9': '自动返航',
        '10': '自动降落',
        '11': '强制降落',
        '12': '三桨叶降落',
        '13': '升级中',
        '14': '未连接'
      },
      gearObj: {
        '0': 'A',
        '1': 'P',
        '2': 'NAV',
        '3': 'FPV',
        '4': 'FARM',
        '5': 'S',
        '6': 'F',
        '7': 'M',
        '8': 'G',
        '9': 'T'
      },
      windDirectionObj: {
        '1': '正北',
        '2': '东北',
        '3': '东',
        '4': '东南',
        '5': '南',
        '6': '西南',
        '7': '西',
        '8': '西北'
      },
      airportSn: '',
      hideLeftFlag: true,
      ratio: 100
    };
  },
  computed: {
    moveLeftStyle() {
      let left = '22.857rem';
      let transform = this.hideLeftFlag ? 'translateX(-21.428rem)' : 'translateX(0)';
      let style = 'left:' + left + ';transform:' + transform + ';';
      return style;
    }
  },
  created() {
    console.log("globalWidth", this.globalWidth);
    if (this.$route.query.id) {
      this.uavId = this.$route.query.id;
      (0, _rem.resetRem)();
      // // 改变窗口大小时重新设置 rem
      // window.onresize = () => {
      //   resetRem();
      // };
      this.init();
    } else {
      this.$message({
        message: '数据异常',
        type: 'error'
      });
      setTimeout(() => {
        this.$router.go(-1);
      }, 2500);
    }
  },
  mounted() {
    // 获取地图实例
    setTimeout(() => {
      this.map = AMap.Map;
    }, 1000);

    // let format = "%Y年 %B %e日 周%A %H:%M:%S";
    // this.countTime = setInterval(() => {
    //     this.dateStr = (new Date).strftime(format, "zh");
    // }, 1000)
  },
  beforeDestroy() {
    if (this.player) {
      this.player.destroy();
      this.player = undefined;
    }
    this.liveUrl = '';
    if (this.airportPlayer) {
      this.airportPlayer.destroy();
      this.airportPlayer = undefined;
    }
    this.airportLiveUrl = '';
    if (this.airportUavFPVPlayer) {
      this.airportUavFPVPlayer.destroy();
      this.airportUavFPVPlayer = undefined;
    }
    this.airportUavFPVLiveUrl = '';
    if (this.interval != null) {
      clearInterval(this.interval);
      this.interval = null;
    }
    if (this.timer != null) {
      clearInterval(this.timer);
      this.timer = null;
    }
    if (this.loadingFlag != null) {
      this.loadingFlag.close();
      this.loadingFlag = null;
    }
    if (this.airportStateInterval != null) {
      clearInterval(this.airportStateInterval);
      this.airportStateInterval = null;
    }
  },
  methods: {
    hideLeftBoxClick() {
      this.hideLeftFlag = !this.hideLeftFlag;
      console.log(this.hideLeftFlag);
    },
    init() {
      this.getCenterpoint();
      this.getUavMsg();
    },
    getCenterpoint() {
      _centerPoint.default.queryCenterPoint().then(res => {
        console.log("queryCenterPoint", res);
        this.center = [res.lngGD, res.latGD];
      });
    },
    getUavMsg() {
      _uav.default.queryDetailById(this.uavId).then(response => {
        console.log('queryDetailById', response);
        this.uav = response.data.uav;
        // 无人机实时数据
        this.getUavData();
        // 查询当前无人机是否绑定了机场,绑定则持续获取数据
        this.checkAirport();
      });
    },
    checkAirport() {
      const data = {
        childSn: this.uav.uavSn
      };
      _manage.default.checkAirportByUavSn(data).then(res => {
        if (res.data.result == 'success') {
          this.hideLeftFlag = false;
          this.airportSn = res.data.sn;
          this.airportName = res.data.name;
          this.airportFlag = true;
          if (res.data.lastPoint) {
            const pointArr = res.data.lastPoint.split('_');
            const airportLng = Number(pointArr[0]);
            const airportLat = Number(pointArr[1]);
            this.airportPosition = [airportLng, airportLat];
            this.center = [airportLng, airportLat];
            console.log('checkAirportByUavSn---', this.center);
          }
          this.getTaskList();
          this.interval = setInterval(() => {
            this.getAirportOSD();
          }, 1000);
        }
      });
    },
    getAirportOSD() {
      const data = {
        hangarSn: this.airportSn
      };
      _hangar.default.queryDockOSDBySN(data).then(res => {
        // console.log("queryDockOSDBySN", res)
        // 机库在线状态：0-离线， 1-在线
        this.airportOnline = res.data.online;
        if (res.data.online == 1) {
          const airport = res.data.osd;
          if (!this.airportLoaded) {
            if (airport.longitude > 0 && airport.latitude > 0) {
              const pointArr = _coordtransform.default.wgs84togcj02(airport.longitude, airport.latitude);
              // 只加载一次
              if (!this.loadCenter) {
                this.loadCenter = true;
                this.center = pointArr;
              }
              const airportLng = pointArr[0].toFixed(10);
              const airportLat = pointArr[1].toFixed(10);
              this.airportPosition = [airportLng, airportLat];
              this.$forceUpdate();
            }

            // this.showUavFlag = false
            this.airportLoaded = true;
            if (!this.loadUavTaskFlag) {
              this.loadUavTaskFlag = true;
              // 如果无人机在线，第一时间查询无人机的任务执行情况，且只查询一次
              this.getUavTaskMsg();
            }
          }

          // 格式化数据
          // 充电状态：0-空闲，1-充电中
          switch (airport.droneChargeState.state) {
            case 1:
              airport.droneChargeState.stateStr = '充电中';
              break;
            default:
              airport.droneChargeState.stateStr = '空闲';
              break;
          }
          // 机场状态："0":"空闲中","1":"现场调试","2":"远程调试","3":"固件升级中","4":"作业中"
          switch (airport.modeCode) {
            case 1:
              airport.modeCodeStr = '现场调试';
              break;
            case 2:
              airport.modeCodeStr = '远程调试';
              break;
            case 3:
              airport.modeCodeStr = '固件升级中';
              break;
            case 4:
              airport.modeCodeStr = '作业中';
              break;
            default:
              airport.modeCodeStr = '空闲中';
              break;
          }
          // 舱盖状态："0":"关闭","1":"打开","2":"半开","3":"舱盖状态异常"
          switch (airport.coverState) {
            case 1:
              airport.coverStateStr = '打开';
              break;
            case 2:
              airport.coverStateStr = '半开';
              break;
            case 3:
              airport.coverStateStr = '舱盖状态异常';
              break;
            default:
              airport.coverStateStr = '关闭';
              break;
          }
          // 推杆状态："0":"关闭","1":"打开","2":"半开","3":"推杆状态异常"
          switch (airport.putterState) {
            case 1:
              airport.putterStateStr = '打开';
              break;
            case 2:
              airport.putterStateStr = '半开';
              break;
            case 3:
              airport.putterStateStr = '推杆状态异常';
              break;
            default:
              airport.putterStateStr = '夹紧';
              break;
          }
          // 补光灯状态："0":"关闭","1":"打开"
          switch (airport.supplementLightState) {
            case 1:
              airport.supplementLightStateStr = '打开';
              break;
            default:
              airport.supplementLightStateStr = '关闭';
              break;
          }
          // 飞机是否在舱："0":"舱外","1":"舱内"
          switch (airport.droneInDock) {
            case 1:
              airport.droneInDockStr = '舱内';
              break;
            default:
              airport.droneInDockStr = '舱外';
              break;
          }
          // 机场声光报警状态："0":"声光报警关闭","1":"声光报警开启"
          switch (airport.alarmState) {
            case 1:
              airport.alarmStateStr = '开启';
              break;
            default:
              airport.alarmStateStr = '关闭';
              break;
          }
          // 降雨量："0":"无雨","1":"小雨","2":"中雨","3":"大雨"
          switch (airport.rainfall) {
            case 1:
              airport.rainfallStr = '小雨';
              break;
            case 2:
              airport.rainfallStr = '中雨';
              break;
            case 3:
              airport.rainfallStr = '大雨';
              break;
            default:
              airport.rainfallStr = '无雨';
              break;
          }
          // 空调模式：{"0":"空闲模式（无制冷、制热、除湿等）","1":"制冷模式","2":"制热模式","3":"除湿模式",
          //  "4":"制冷退出模式","5":"制热退出模式","6":"除湿退出模式","7":"制冷准备模式","8":"制热准备模式","9":"除湿准备模式"}
          switch (airport.airConditionerMode) {
            case 1:
              airport.airModeStr = '制冷';
              break;
            case 2:
              airport.airModeStr = '加热';
              break;
            case 3:
              airport.airModeStr = '除湿';
              break;
            case 4:
              airport.airModeStr = '制冷退出';
              break;
            case 5:
              airport.airModeStr = '加热退出';
              break;
            case 6:
              airport.airModeStr = '除湿退出';
              break;
            case 7:
              airport.airModeStr = '制冷准备';
              break;
            case 8:
              airport.airModeStr = '加热准备';
              break;
            case 9:
              airport.airModeStr = '除湿准备';
              break;
            default:
              airport.airModeStr = '空闲';
              break;
          }
          switch (airport.emergencyStopState) {
            case 1:
              airport.emergencyStopStateStr = '开启';
              break;
            default:
              airport.emergencyStopStateStr = '关闭';
              break;
          }
          airport.temperatureStr = Math.round(airport.temperature ? airport.temperature : 0);
          airport.humidityStr = Math.round(airport.humidity ? airport.humidity : 0);
          airport.environmentTemperatureStr = Math.round(airport.environmentTemperature ? airport.environmentTemperature : 0);
          airport.windSpeedStr = Math.round(airport.windSpeed ? airport.windSpeed : 0);

          // console.log("checkAirportByUav",airport)
          this.airportOSD = airport;
        }
      }).catch(res => {
        this.$message({
          message: '机场数据加载异常',
          type: 'error'
        });
        if (this.airportStateInterval != null) {
          clearInterval(this.airportStateInterval);
          this.airportStateInterval = null;
        }
      });
    },
    // 查询该无人机可执行的任务
    getTaskList() {
      const data = {
        uavId: this.uavId
      };
      _flighttask.default.queryFlightTaskListByUavId(data).then(res => {
        if (res.data.result == 'success') {
          this.taskList = res.data.list;
        }
      });
    },
    getUavTaskMsg() {
      const data = {
        id: this.uavId
      };
      _uav.default.queryFlightTaskMsgByUav(data).then(res => {
        console.log('queryFlightTaskMsgByUav', res);
        if (res.data.result == 'success') {
          // 无人机正在执飞
          this.taskFlag = 1;
          this.task = res.data.task;
          const list = res.data.taskPointList;
          const taskPointList = [];
          const taskLinePath = [];
          for (let x = 0; x < list.length; x++) {
            const arr = [list[x].longitude, list[x].latitude];
            const p = {
              position: arr
            };
            taskPointList.push(p);
            taskLinePath.push(arr);
          }
          this.taskLinePath = taskLinePath;
          this.taskPointList = taskPointList;
          const pointList = res.data.pointList;
          console.log('已飞点列表：', pointList.length);
          if (pointList.length > 0) {
            this.timer = setInterval(() => {
              if (pointList.length > 100) {
                const list = pointList.splice(0, 100);
                const points = [];
                list.forEach(el => {
                  if (el.gcj02Lng > 0 && el.gcj02Lng > 0) {
                    points.push([el.gcj02Lng, el.gcj02Lat]);
                  }
                });
                this.polylinePath = this.polylinePath.concat(points);
              } else {
                console.log('历史轨迹加载结束');
                const list = pointList.splice(0);
                const points = [];
                list.forEach(el => {
                  if (el.gcj02Lng > 0 && el.gcj02Lng > 0) {
                    points.push([el.gcj02Lng, el.gcj02Lat]);
                  }
                });
                this.polylinePath = this.polylinePath.concat(points);
                clearInterval(this.timer);
                this.timer = null;
                this.loadPathFlag = true;
                this.$forceUpdate();
              }
            }, 100);
          } else {
            this.timer = null;
            this.loadPathFlag = true;
          }
        } else {
          // 无人机不在执飞
          this.taskFlag = 0;
          this.timer = null;
          this.loadPathFlag = true;
        }
      });
    },
    // 获取无人机实时数据
    getUavData() {
      const data = {
        uavSn: this.uav.uavSn
      };
      this.interval = setInterval(() => {
        this.getUavOSD(data);
      }, 1000);
    },
    getUavOSD(data) {
      _uav.default.queryUavOSDBySN(data).then(res => {
        // console.log("queryUavOSDBySN", res)
        if (res.data.online == '1') {
          const osd = res.data.osd;
          const lng = osd.longitude;
          const lat = osd.latitude;
          if (lng > 0 && lat > 0) {
            const gcj02Point = _coordtransform.default.wgs84togcj02(lng, lat);
            gcj02Point[0] = gcj02Point[0].toFixed(10);
            gcj02Point[1] = gcj02Point[1].toFixed(10);
            osd.longitude = gcj02Point[0];
            osd.latitude = gcj02Point[1];
            if (!this.loadCenter && !this.airportFlag) {
              this.loadCenter = true;
              this.center = gcj02Point;
            }
            const uavPoint = {};
            uavPoint.position = gcj02Point;
            this.uavPoint = uavPoint;
            if (this.loadPathFlag) {
              this.polylinePath.push(uavPoint.position);
            }
          }
          osd.horizontalSpeed = osd.horizontalSpeed ? osd.horizontalSpeed.toFixed(2) : 0;
          osd.verticalSpeed = osd.verticalSpeed ? osd.verticalSpeed.toFixed(2) : 0;
          osd.height = osd.height ? osd.height.toFixed(2) : 0;
          osd.modeCodeStr = this.modeCodeObj[osd.modeCode];
          osd.gearStr = this.gearObj[osd.gear];
          osd.elevation = osd.elevation ? osd.elevation.toFixed(2) : 0;
          this.horizontalSpeedPoint = Math.round(osd.horizontalSpeed / 100 * 300 - 150);
          this.verticalSpeedPoint = Math.round(osd.verticalSpeed / 100 * 300 - 150);
          this.uavYawPoint = Math.round(osd.attitudeHead);
          this.uavPitchPoint = Math.round(osd.attitudePitch);
          if (osd.modeCode == 9 || osd.modeCode == 10 || osd.modeCode == 11 || osd.modeCode == 12) {
            this.task = {
              name: '',
              createTime: ''
            };
            this.taskFlag = 0;
          }
          if (!this.airportFlag) {
            osd.windSpeed = osd.windSpeed ? osd.windSpeed.toFixed(2) : 0;
            if (osd.windDirection > 0) {
              osd.windDirectionStr = this.windDirectionObj[osd.windDirection];
            } else {
              osd.windDirectionStr = '/';
            }
          }
          // if (osd.payloads) {
          // 云台列表
          // let list = osd.payloads
          // for (let x = 0; x < list.length; x++) {
          //         if (data.ptzState.pitch) {
          //     this.yuntaiPitchValue = Math.round((data.ptzState.pitch + 120) / 150 * 100)
          //     this.yuntaiPitchPoint = Math.round(data.ptzState.pitch)
          // }
          // if (data.ptzState.yaw) {
          //     this.yuntaiYawValue = Math.round((data.ptzState.yaw + 180) / 360 * 100)
          // }
          // if (data.ptzState.roll) {
          //     this.yuntaiRollValue = Math.round((data.ptzState.roll + 90) / 150 * 100)
          // }
          // }
          // }
          this.uavOSD = osd;
        }
        this.onLine = res.data.online;
      });
    },
    playTaskClick() {
      if (this.airportFlag && this.airportOnline != 1) {
        this.$message({
          message: '机场不在线，无法执飞',
          type: 'error'
        });
      } else if (this.airportFlag && this.airportOSD.droneInDock != 1) {
        this.$message({
          message: '无人机不在舱内，无法执飞',
          type: 'error'
        });
      } else if (this.selectTaskValue == '') {
        this.$message({
          message: '请选择要执飞的任务',
          type: 'error'
        });
      } else {
        if (this.uavOSD.modeCode == 10 || this.uavOSD.modeCode == 11 || this.uavOSD.modeCode == 12) {
          this.$message({
            message: '无人机正在降落，请稍后',
            type: 'error'
          });
          return;
        } else if (this.uavOSD.flightMode == 9) {
          this.$message({
            message: '无人机正在返航，请稍后',
            type: 'error'
          });
          return;
        } else if (this.uavOSD.flightMode == 13) {
          this.$message({
            message: '无人机升级中，请稍后',
            type: 'error'
          });
          return;
        }
        const data = {
          id: this.uavId
        };
        // 判断无人机是否在执飞
        _uav.default.checkUavFlightTask(data).then(res => {
          if (res.data.result == 'success') {
            // 允许执飞，开始无人机执飞操作
            this.isLoading();
            const selectObj = {
              id: this.selectTaskValue
            };
            // 有机场，通过机场控制无人机执飞航线
            this.airportRoutePlanning(selectObj);
            this.selectObj = selectObj;
          } else {
            // 不允许执飞
            this.$message({
              message: '无人机正在执行飞行任务',
              type: 'error'
            });
            setTimeout(() => {
              // 如果无人机在线，第一时间查询无人机的任务执行情况，且只查询一次
              this.getUavTaskMsg();
            }, 1000);
          }
        });
      }
    },
    goBackClick() {
      this.$router.push({
        path: '/'
      });
    },
    leftTopBtn(v) {
      if (v != this.leftTopBtnValue) {
        this.leftTopBtnValue = v;
      }
    },
    rightTopBtn(index) {
      if (index != this.rightTopBtnValue) {
        this.rightTopBtnValue = index;
      }
    },
    backHomeClick() {
      // 返航
      this.hangarControl('18');
    },
    // 直播
    liveClick() {
      if (!this.airportFlag) {
        const liveFlag = this.liveFlag;
        if (liveFlag) {
          // 已经打开了直播,关闭直播
          if (this.player) {
            this.player.destroy();
            this.player = undefined;
          }
          this.liveUrl = '';
          // const data = {
          //   deviceSn: this.uav.uavSn,
          //   liveType: 1
          // }
          // this.stopLive(data)
        } else {
          if (this.onLine == '0') {
            this.$message({
              message: '无人机离线，无法查看直播',
              type: 'error'
            });
            return;
          }
          const data = {
            deviceSn: this.uav.uavSn,
            liveType: 1
          };
          // 开启直播
          this.startLive(data);

          /** 测试 start **/
          // this.liveUrl = 'https://sf1-hscdn-tos.pstatp.com/obj/media-fe/xgplayer_doc_video/flv/xgplayer-demo-360p.flv'
          // setTimeout(() => {
          //     this.initFLVPlayer('uav')
          // }, 700);

          // this.airportLiveUrl = 'https://sf1-hscdn-tos.pstatp.com/obj/media-fe/xgplayer_doc_video/flv/xgplayer-demo-360p.flv'
          // setTimeout(() => {
          //     this.initFLVPlayer('airport')
          // }, 700);
          /** 测试 end **/
        }
        this.liveFlag = !liveFlag;
      } else {
        this.liveCheckBoxVisible = !this.liveCheckBoxVisible;
      }
    },
    stopLive(data) {
      _manage.default.stopLive(data).then(res => {
        console.log('stopLive', res);
        if (res.data.result.indexOf('success') > -1) {
          const liveUrl = res.data.url;
          if (data.liveType == '1') {
            this.liveFlag = false;
          } else if (data.liveType == '2') {
            this.airportUavFPVLiveFlag = false;
          } else if (data.liveType == '3') {
            this.airportLiveFlag = false;
          }
          if (this.airportFlag) {
            item.check = false;
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    },
    liveChooseClick(item) {
      if (item.name.indexOf('无人机') > -1) {
        const liveType = item.name.indexOf('FPV') > -1 ? 2 : 1;
        let flag = false;
        if (liveType == 1) {
          flag = this.liveFlag;
        } else if (liveType == 2) {
          flag = this.airportUavFPVLiveFlag;
        }
        if (flag) {
          if (liveType == 1) {
            // 已经打开了云台直播,关闭直播
            if (this.player) {
              this.player.destroy();
              this.player = undefined;
            }
            this.liveUrl = '';
            this.liveFlag = false;
          } else if (liveType == 2) {
            // 已经打开了FPV直播,关闭直播
            if (this.airportUavFPVPlayer) {
              this.airportUavFPVPlayer.destroy();
              this.airportUavFPVPlayer = undefined;
            }
            this.airportUavFPVLiveUrl = '';
            this.airportUavFPVLiveFlag = false;
            const data = {
              deviceSn: this.uav.uavSn,
              liveType: 2
            };
            this.stopLive(data);
          }
          item.check = false;
        } else {
          if (this.onLine == '0') {
            this.$message({
              message: '无人机离线，无法查看直播',
              type: 'error'
            });
            return;
          }
          const data = {
            deviceSn: this.uav.uavSn,
            liveType
          };
          // 开启直播
          this.startLive(data, item);

          /** 测试 start **/
          // this.liveUrl = 'https://sf1-hscdn-tos.pstatp.com/obj/media-fe/xgplayer_doc_video/flv/xgplayer-demo-360p.flv'
          // setTimeout(() => {
          //     this.initFLVPlayer('uav')
          // }, 700);
          /** 测试 end **/
        }
      } else if (item.name == '机场') {
        const airportLiveFlag = this.airportLiveFlag;
        if (airportLiveFlag) {
          // 已经打开了直播,关闭直播
          if (this.airportPlayer) {
            this.airportPlayer.destroy();
            this.airportPlayer = undefined;
          }
          this.airportLiveUrl = '';
          this.airportLiveFlag = false;
          const data = {
            deviceSn: this.airportSn,
            liveType: 3
          };
          this.stopLive(data);
          item.check = false;
        } else {
          if (this.airportOnline != '1') {
            this.$message({
              message: '机场离线，无法查看直播',
              type: 'error'
            });
            return;
          }
          const data = {
            deviceSn: this.airportSn,
            liveType: 3
          };
          this.startLive(data, item);

          /** 测试 start **/
          // this.airportLiveUrl = 'https://sf1-hscdn-tos.pstatp.com/obj/media-fe/xgplayer_doc_video/flv/xgplayer-demo-360p.flv'
          // setTimeout(() => {
          //     this.initFLVPlayer('airport')
          // }, 700);
          /** 测试 end **/
        }
      }
    },
    startLive(data, item) {
      _manage.default.startLive(data).then(res => {
        console.log('startLive', res);
        if (res.data.result.indexOf('success') > -1) {
          const liveUrl = res.data.url;
          if (data.liveType == '1') {
            this.liveUrl = liveUrl;
            console.log('liveUrl', this.liveUrl);
            this.liveFlag = true;
          } else if (data.liveType == '2') {
            this.airportUavFPVLiveUrl = liveUrl;
            console.log('airportUavFPVLiveUrl', this.airportUavFPVLiveUrl);
            this.airportUavFPVLiveFlag = true;
          } else if (data.liveType == '3') {
            this.airportLiveUrl = liveUrl;
            console.log('airportLiveUrl', this.airportLiveUrl);
            this.airportLiveFlag = true;
          }
          if (this.airportFlag) {
            item.check = true;
          }
          setTimeout(() => {
            this.initVideoPlayer(liveUrl, data.liveType);
          }, 700);
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    },
    // 初始化播放器方法
    initVideoPlayer(liveUrl, liveType) {
      let id = '';
      if (liveType == 1) {
        id = 'video';
      } else if (liveType == 2) {
        id = 'airportUavFPVVideo';
      } else if (liveType == 3) {
        id = 'airportVideo';
      }
      console.log('initVideoPlayer---id', id);
      var video = document.getElementById(id);
      var option = {
        video,
        autoplay: true
      };
      const player = new _jswebrtcMin.default.Player(liveUrl, option);
      if (liveType == 1) {
        this.player = player;
      } else if (liveType == 2) {
        this.airportUavFPVPlayer = player;
      } else if (liveType == 3) {
        this.airportPlayer = player;
      }
    },
    /** 无人机执飞逻辑 start **/
    isLoading() {
      this.loadingFlag = this.$loading({
        lock: true,
        text: '执飞准备中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)'
      });
    },
    // 机场开始执飞任务
    airportRoutePlanning(obj) {
      _flighttaskrecord.default.updateFlyById(obj).then(response => {
        this.selectObj = {};
        this.loadingFlag.close();
        if (!response.success) {
          this.$message({
            type: 'error',
            message: response.message
          });
        } else if (response.success) {
          this.$message({
            type: 'success',
            message: '执飞成功!'
          });
          setTimeout(() => {
            this.getUavTaskMsg();
          }, 1000);
        }
      }).catch(res => {
        this.selectObj = {};
        this.loadingFlag.close();
      });
    },
    /** 无人机执飞逻辑 end **/

    /** 机库操控 start **/
    airportControlClick(type, state) {
      if (type == 'cover') {
        this.coverClick(state);
      } else if (type == 'putter') {
        this.putterClick(state);
      } else if (type == 'uavOnline') {
        this.uavOnlineClick(state);
      } else if (type == 'charge') {
        this.chargeClick(state);
      } else if (type == 'supplementLight') {
        this.supplementLightClick(state);
      } else if (type == 'alarm') {
        this.alarmClick(state);
      } else if (type == 'debug') {
        this.airportDebugClick(state);
      }
    },
    // 机库调试模式
    airportDebugClick(state) {
      if (this.airportOnline == 1) {
        if (this.airportOSD.modeCode != state) {
          let type = '';
          if (state == 2) {
            type = '12';
            // this.airportOSD.modeCode = '2'
          } else {
            type = '13';
            // this.airportOSD.modeCode = '0'
          }
          this.hangarControl(type);
        }
      } else {
        this.$message({
          type: 'error',
          message: '机场离线，无法操控'
        });
      }
    },
    // 舱门控制
    coverClick(state) {
      if (this.airportOnline == 1) {
        if (this.airportOSD.coverState != state) {
          let type = '';
          if (state == 1) {
            type = '2';
          } else {
            type = '3';
          }
          this.hangarControl(type);
        }
      } else {
        this.$message({
          type: 'error',
          message: '机场离线，无法操控'
        });
      }
    },
    // 推杆控制
    putterClick(state) {
      if (this.airportOnline == 1) {
        if (this.airportOSD.putterState != state) {
          let type = '';
          if (state == 1) {
            type = '11';
          } else {
            type = '10';
          }
          console.log('putterClick', type);
          this.hangarControl(type);
        }
      } else {
        this.$message({
          type: 'error',
          message: '机场离线，无法操控'
        });
      }
    },
    // 机场控制无人机开关机
    uavOnlineClick(state) {
      if (this.airportOnline == 1) {
        if (this.airportOSD.droneInDock != 1) {
          this.$message({
            type: 'error',
            message: '无人机不在机舱内，无法操控'
          });
        } else {
          const uavState = this.onLine;
          if (uavState != state) {
            let type = '';
            if (state == 1) {
              type = '6';
            } else {
              type = '7';
            }
            this.hangarControl(type);
          }
        }
      } else {
        this.$message({
          type: 'error',
          message: '机场离线，无法操控'
        });
      }
    },
    // 充电控制
    chargeClick(state) {
      if (this.airportOnline == 1) {
        if (this.airportOSD.chargeState != state) {
          let type = '';
          if (state == 1) {
            type = '8';
          } else {
            type = '9';
          }
          this.hangarControl(type);
        }
      } else {
        this.$message({
          type: 'error',
          message: '机场离线，无法操控'
        });
      }
    },
    // 补光灯控制
    supplementLightClick(state) {
      if (this.airportOnline == 1) {
        if (this.airportOSD.supplementLightState != state) {
          let type = '';
          if (state == 1) {
            type = '14';
          } else {
            type = '15';
          }
          this.hangarControl(type);
        }
      } else {
        this.$message({
          type: 'error',
          message: '机场离线，无法操控'
        });
      }
    },
    // 声光报警控制
    alarmClick(state) {
      if (this.airportOnline == 1) {
        if (this.airportOSD.alarmState != state) {
          let type = '';
          if (state == 1) {
            type = '16';
          } else {
            type = '17';
          }
          this.hangarControl(type);
        }
      } else {
        this.$message({
          type: 'error',
          message: '机场离线，无法操控'
        });
      }
    },
    hangarControl(type) {
      console.log('hangarControl', type);
      const data = {
        sn: this.airportSn,
        type
      };
      _hangar.default.hangarControl(data).then(res => {
        if (res.data.result == 'success') {
          let message = '操控成功';
          if (type == 18) {
            message = '开始返航';
          }
          this.$message({
            type: 'success',
            message
          });
        } else {
          this.$message({
            type: 'error',
            message: '操控失败'
          });
        }
      });
    },
    /** 机库操控 end **/

    // 根据经纬度计算距离，参数分别为第一点的纬度，经度；第二点的纬度，经度
    getDistance(lat1, lng1, lat2, lng2) {
      var radLat1 = this.rad(lat1);
      var radLat2 = this.rad(lat2);
      var a = this.radLat1 - this.radLat2;
      var b = this.rad(lng1) - this.rad(lng2);
      var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
      s = s * 6378.137; // EARTH_RADIUS;
      s = Math.round(s * 10000) / 10000; // 输出为公里

      var distance = s;
      var distance_str = '';
      if (parseInt(distance) >= 1) {
        distance_str = distance.toFixed(1) + 'km';
      } else {
        distance_str = distance * 1000 + 'm';
      }

      // s=s.toFixed(4);

      // console.info('lyj 距离是', s);
      // console.info('lyj 距离是', distance_str);
      return s;
    },
    // 经纬度转换成三角函数中度分表形式。
    rad(d) {
      return d * Math.PI / 180.0;
    }
  }
};