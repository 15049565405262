"use strict";

var _interopRequireDefault = require("/var/jenkins_home/node_modules_list/test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _default = exports.default = {
  //查询所有无人机列表
  queryAllUav() {
    return (0, _request.default)({
      url: `/api/uav/queryAllUav`,
      method: 'get'
    });
  },
  //分页查询无人机列表数据
  queryListByPage(data) {
    return (0, _request.default)({
      url: `/api/uav/queryUavListByPage`,
      method: 'post',
      //后端用requestBody获取数据时,data表示把对象转换成json进行数据传递
      data: data
    });
  },
  //新增无人机数据
  save(form) {
    return (0, _request.default)({
      url: `/api/uav/add`,
      method: 'post',
      //后端用requestBody获取数据时,data表示把对象转换成json进行数据传递
      data: form
    });
  },
  //更新无人机数据
  updateById(form) {
    return (0, _request.default)({
      url: `/api/uav/edit`,
      method: 'post',
      //后端用requestBody获取数据时,data表示把对象转换成json进行数据传递
      data: form
    });
  },
  //查询无人机数据
  queryById(id) {
    return (0, _request.default)({
      url: `/api/uav/queryById/${id}`,
      method: 'get'
    });
  },
  //查询无人机详细信息
  queryDetailById(id) {
    return (0, _request.default)({
      url: `/api/uav/queryDetailById/${id}`,
      method: 'get'
    });
  },
  //删除无人机数据
  deleteById(id) {
    return (0, _request.default)({
      url: `/api/uav/deleteById/${id}`,
      method: 'delete'
    });
  },
  // 查询飞机型号列表
  queryUavModelList() {
    return (0, _request.default)({
      url: `/api/uav/uavModel/queryUavModelList`,
      method: 'get'
      //后端用requestBody获取数据时,data表示把对象转换成json进行数据传递
    });
  },
  // 根据飞机型号查询能挂载的设备列表
  queryUavMountList(data) {
    return (0, _request.default)({
      url: `/api/uav/uavMount/queryListByUavModelId`,
      method: 'post',
      //后端用requestBody获取数据时,data表示把对象转换成json进行数据传递
      data
    });
  },
  // 查询无人机实时数据
  queryUavOSDBySN(data) {
    return (0, _request.default)({
      url: `/api/uav/queryUavOSDBySN`,
      method: 'post',
      data
    });
  },
  // 开始无人机直播
  startUavLive(data) {
    return (0, _request.default)({
      url: `/api/uav/startUavLive`,
      method: 'post',
      data
    });
  },
  /** 云盒相关接口 */

  // 查询云盒列表
  queryCloudBoxList() {
    return (0, _request.default)({
      url: `/api/cloudbox/queryList`,
      method: 'post'
    });
  },
  openPullLive(cloudBoxSn) {
    return (0, _request.default)({
      url: `/api/cloudbox/openPullLive?cloudBoxSn=${cloudBoxSn}`,
      method: 'get'
    });
  },
  // 获取直播地址
  queryLiveUrl(data) {
    return (0, _request.default)({
      url: `/api/cloudbox/queryLiveUrl?cloudBoxSn=${data.cloudBoxSn}`,
      method: 'get'
    });
  },
  // 连接tcp
  connectCloudBox(data) {
    return (0, _request.default)({
      url: `/api/cloudbox/connectCloudBox?cloudBoxSn=${data.cloudBoxSn}`,
      method: 'get'
    });
  },
  // 关闭指定tpc
  closeCloudBoxTcp(cloudBoxSn) {
    return (0, _request.default)({
      url: `/api/cloudbox/closeCloudBoxTcp?cloudBoxSn=${cloudBoxSn}`,
      method: 'get'
    });
  },
  // 判断云盒是否在操控中
  checkCloudBoxControlState(data) {
    return (0, _request.default)({
      url: `/api/cloudbox/checkCloudBoxControlState?cloudBoxSn=${data.cloudBoxSn}`,
      method: 'get'
    });
  },
  // 拍照
  cloudBoxPhotograph(cloudBoxSn) {
    return (0, _request.default)({
      url: `/api/cloudbox/takePictures?cloudBoxSn=${cloudBoxSn}`,
      method: 'get'
    });
  },
  // 暂停/恢复航线任务
  pauseTask(data) {
    return (0, _request.default)({
      url: `/api/cloudbox/pauseTask?cloudBoxSn=${data.cloudBoxSn}&type=${data.type}`,
      method: 'get'
    });
  },
  // 开始/取消返航
  backHome(data) {
    return (0, _request.default)({
      url: `/api/cloudbox/backHome?cloudBoxSn=${data.cloudBoxSn}&type=${data.type}`,
      method: 'get'
    });
  },
  // 开始/结束录像
  liveVideo(data) {
    return (0, _request.default)({
      url: `/api/cloudbox/liveVideo?cloudBoxSn=${data.cloudBoxSn}&type=${data.type}`,
      method: 'get'
    });
  },
  // 开始指点飞行
  startPositionFly(data) {
    return (0, _request.default)({
      url: `/api/cloudbox/startPositionFly`,
      data,
      method: 'post'
    });
  },
  // 停止指点飞行
  stopPositionFly(data) {
    return (0, _request.default)({
      url: `/api/cloudbox/stopPositionFly`,
      data,
      method: 'post'
    });
  },
  // 获取/释放无人机控制权
  gainAbilityControlUav(data) {
    return (0, _request.default)({
      url: `/api/cloudbox/gainAbilityControlUav?cloudBoxSn=${data.cloudBoxSn}&type=${data.type}`,
      method: 'get'
    });
  },
  // 操控无人机
  controlUav(data) {
    return (0, _request.default)({
      url: `/api/cloudbox/controlUav`,
      method: 'post',
      data
    });
  },
  // 操控云台
  controlPTZ(data) {
    return (0, _request.default)({
      url: `/api/cloudbox/controlPTZ`,
      method: 'post',
      data
    });
  },
  // 更改云台的相源
  changePTZCameraType(data) {
    return (0, _request.default)({
      url: `/api/cloudbox/changePTZCameraType`,
      method: 'post',
      data
    });
  },
  // 设置云台模式
  changePTZCameraMode(data) {
    return (0, _request.default)({
      url: `/api/cloudbox/changePTZCameraMode`,
      method: 'post',
      data
    });
  },
  // 获取无人机下行遥测实时数据
  queryTelemetryDataBySN(data) {
    return (0, _request.default)({
      url: `/api/cloudbox/queryTelemetryDataBySN`,
      method: 'post',
      data
    });
  },
  // 获取无人机状态实时数据
  queryUavStateDataBySN(data) {
    return (0, _request.default)({
      url: `/api/cloudbox/queryUavStateDataBySN`,
      method: 'post',
      data
    });
  },
  // 查询无人机的执飞状态
  queryFlightTaskMsgByUav(data) {
    return (0, _request.default)({
      url: `/api/uav/queryFlightTaskMsgByUav`,
      method: 'post',
      data
    });
  },
  // 查询无人机的累计飞行数据
  queryTotalDataByUavId(data) {
    return (0, _request.default)({
      url: `/api/uav/queryTotalDataByUavId`,
      method: 'post',
      data
    });
  },
  // 判断无人机是否允许执飞
  checkUavFlightTask(data) {
    return (0, _request.default)({
      url: `/api/uav/checkUavFlightTask`,
      method: 'post',
      data
    });
  },
  // 云台测距
  rangeFindingPTZCamera(data) {
    return (0, _request.default)({
      url: `/api/cloudbox/rangeFindingPTZCamera`,
      method: 'post',
      data
    });
  },
  // 根据无人机查询是否绑定了机库
  checkAirportByUav(data) {
    return (0, _request.default)({
      url: `/api/cloudbox/checkAirportByUav`,
      method: 'post',
      data
    });
  },
  // 查询云盒的飞行历史
  queryCloudBoxVideoHistoryList(data) {
    return (0, _request.default)({
      url: `/api/cloudbox/queryCloudBoxVideoHistoryList`,
      method: 'post',
      data
    });
  },
  // 批量下载回放视频
  downloadVideoByList(data) {
    return (0, _request.default)({
      url: `/api/cloudbox/cloudboxdownloadrecord/downloadVideoByList`,
      method: 'post',
      data
    });
  },
  // 查询无人机视频录播列表
  queryUavHistoryVideoList(data) {
    return (0, _request.default)({
      url: `/api/uav/queryUavHistoryVideoList`,
      method: 'post',
      data
    });
  },
  // 查询所有无人机的累计飞行数据
  queryTotalData(data) {
    return (0, _request.default)({
      url: `/api/uav/queryTotalData`,
      method: 'post',
      data
    });
  },
  queryUavListWithDept(data) {
    return (0, _request.default)({
      url: `/api/uav/queryUavListWithDept`,
      method: 'post',
      data
    });
  },
  queryUavOSDByIds(data) {
    return (0, _request.default)({
      url: `/api/uav/queryUavOSDByIds`,
      method: 'post',
      data
    });
  },
  websocketTest(data) {
    return (0, _request.default)({
      url: `/api/uav/websocketTest`,
      method: 'post',
      data
    });
  }
};