module.exports = {
  /**
     * 中心点坐标
     */
  //   centerPoint: [121.498586, 31.239637],
  centerPoint: [118.728366, 31.985120],
  /**
     * 地图级别
     */
  zoom: 17,
  /**
   * 飞行高度默认值: 50m
   */
  flightHeight: 80,
  /**
     * 网页可视化高度
     */
  // windowHeight:window.innerHeight
  windowHeight: document.documentElement.clientHeight || document.body.clientHeight,
  /**
     * 网页可视化宽度
     */
  windowWidth: document.documentElement.clientWidth || document.body.clientWidth,
  /**
     * mqtt信息
     */
  mqttPath: 'ws://123.166.63.177:1883/mqtt',
  mqttUsername: 'admin',
  mqttPassword: 'qwer!@#$1234',
  /**
   * 公用模型地址
   */
  //   VUE_MODEL_BASE_PATH: 'http://localhost:3000'
  VUE_MODEL_BASE_PATH: 'http://123.166.63.177:8003'
};