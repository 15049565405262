"use strict";

var _interopRequireDefault = require("/var/jenkins_home/node_modules_list/test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _Breadcrumb = _interopRequireDefault(require("@/components/Breadcrumb"));
var _Hamburger = _interopRequireDefault(require("@/components/Hamburger"));
var _Screenfull = _interopRequireDefault(require("@/components/Screenfull"));
var _HeaderSearch = _interopRequireDefault(require("@/components/HeaderSearch"));
var _avatar = _interopRequireDefault(require("@/assets/images/avatar.png"));
var _centerPoint = _interopRequireDefault(require("@/api/centerPoint/centerPoint"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
// import Doc from '@/components/Doc'
// import SizeSelect from '@/components/SizeSelect'
var _default = exports.default = {
  components: {
    Breadcrumb: _Breadcrumb.default,
    Hamburger: _Hamburger.default,
    Screenfull: _Screenfull.default,
    // SizeSelect,
    Search: _HeaderSearch.default
    // Doc
  },
  data() {
    return {
      Avatar: _avatar.default,
      dialogVisible: false,
      dialogFormVisible: false,
      formLabelWidth: '50px',
      form: {
        id: null,
        lng: 118.894953,
        lat: 31.912943
      },
      rules: {
        lng: [{
          required: true,
          message: '请输入活动经度',
          trigger: 'blur'
        }],
        lat: [{
          required: true,
          message: '请输入活动纬度',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['sidebar', 'device', 'user', 'baseApi'])
    // show: {
    //   get() {
    //     return this.$store.state.settings.showSettings
    //   },
    //   set(val) {
    //     this.$store.dispatch('settings/changeSetting', {
    //       key: 'showSettings',
    //       value: val
    //     })
    //   }
    // }
  },
  created() {
    this.queryCenter();
  },
  methods: {
    // 查询经纬度
    queryCenter() {
      _centerPoint.default.queryCenterPoint().then(res => {
        if (res != null && res != "") {
          this.form = res;
          _jsCookie.default.set('cp', JSON.stringify(res));
        }
      });
    },
    // 提交坐标
    commitCenter(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          _centerPoint.default.saveOrUp(this.form).then(_ => {
            this.$message.success('修改成功！');
            _jsCookie.default.set('cp', JSON.stringify(this.form));
          });
        } else {
          this.$message.warning('请正确数据参数！');
          return false;
        }
      });
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    open() {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.logout();
      });
    },
    logout() {
      this.$store.dispatch('LogOut').then(() => {
        location.reload();
      });
    }
  }
};