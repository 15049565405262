"use strict";

var _interopRequireDefault = require("/var/jenkins_home/node_modules_list/test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRouterMap = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _index = _interopRequireDefault(require("../layout/index"));
var _index2 = _interopRequireDefault(require("../views/controlcenter/index"));
_vue.default.use(_vueRouter.default);
const constantRouterMap = exports.constantRouterMap = [{
  path: '/login',
  meta: {
    title: '登录',
    noCache: true
  },
  component: resolve => require(['@/views/login'], resolve),
  hidden: true
}, {
  path: '/404',
  component: resolve => require(['@/views/features/404'], resolve),
  hidden: true
}, {
  path: '/401',
  component: resolve => require(['@/views/features/401'], resolve),
  hidden: true
}, {
  path: '/redirect',
  component: _index.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: resolve => require(['@/views/features/redirect'], resolve)
  }]
},
// {
//   path: '/',
//   component: Layout,
//   redirect: '/dashboard',
//   children: [
//     {
//       path: 'dashboard',
//       component: (resolve) => require(['@/views/home'], resolve),
//       name: 'Dashboard',
//       meta: { title: '首页', icon: 'index', affix: true, noCache: true }
//     }
//   ]
// },
// {
//   path: '/',
//   component: Layout,
//   redirect: '/live',
//   children: [
//     {
//       path: 'live',
//       component: (resolve) => require(['@/views/controlcenter/live'], resolve),
//       name: 'live',
//       meta: { title: '监控大屏', icon: 'index', affix: true, noCache: true }
//     }
//   ]
// },

{
  path: '/',
  component: _index.default,
  redirect: '/homemap',
  children: [{
    path: 'homemap',
    component: resolve => require(['@/views/homemap'], resolve),
    name: 'homemap',
    meta: {
      title: '首页',
      icon: 'home2',
      affix: true,
      noCache: true
    }
  }]
}, {
  path: '/AIRecognition/mapIndex/:id',
  component: resolve => require(['@/views/AIRecognition/mapIndex'], resolve),
  hidden: true,
  meta: {
    title: '病害地图',
    icon: 'index',
    affix: true,
    noCache: true
  }
},
// {
//   path: '/aipage',
//   component: (resolve) => require(['@/views/AIRecognition/indexPage'], resolve),
//   name: 'AIPage',
//   meta: { title: '问题地图', icon: 'index', affix: true, noCache: true }
// },
// {
//   path: '/controlcenterIndex',
//   component: (resolve) => require(['@/views/controlcenter/index'], resolve),
//   meta: { title: '无人机操控' }
// },
{
  path: '/user',
  component: _index.default,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'center',
    component: resolve => require(['@/views/system/user/center'], resolve),
    name: '个人中心',
    meta: {
      title: '个人中心'
    }
  }]
}, {
  path: '/controlcenter/index',
  component: _index2.default
}];
var _default = exports.default = new _vueRouter.default({
  mode: 'hash',
  // mode: 'history',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRouterMap
});