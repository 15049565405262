"use strict";

var _interopRequireDefault = require("/var/jenkins_home/node_modules_list/test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const base_api = '/api/centerpoint';
var _default = exports.default = {
  // 查询中心点坐标
  queryCenterPoint() {
    return (0, _request.default)({
      url: `${base_api}`,
      method: 'get'
    });
  },
  saveOrUp(data) {
    return (0, _request.default)({
      url: `${base_api}`,
      method: 'post',
      data
    });
  }
};