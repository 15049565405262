"use strict";

var _interopRequireDefault = require("/var/jenkins_home/node_modules_list/test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = _interopRequireDefault(require("@/store"));
/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
var _default = exports.default = {
  install(Vue) {
    Vue.prototype.checkPer = value => {
      if (value && value instanceof Array && value.length > 0) {
        const roles = _store.default.getters && _store.default.getters.roles;
        const permissionRoles = value;
        return roles.some(role => {
          return permissionRoles.includes(role);
        });
      } else {
        console.error(`need roles! Like v-permission="['admin','editor']"`);
        return false;
      }
    };
  }
};