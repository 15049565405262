"use strict";

var _interopRequireDefault = require("/var/jenkins_home/node_modules_list/test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _default = exports.default = {
  //查询所有无人机列表
  checkAirportByUavSn(data) {
    return (0, _request.default)({
      url: `/api/manage/manageDevice/checkAirportByUavSn`,
      method: 'post',
      data
    });
  },
  //查询所有无人机列表
  startLive(data) {
    return (0, _request.default)({
      url: `/api/manage/manageDevice/startLive`,
      method: 'post',
      data
    });
  },
  //查询所有无人机列表
  stopLive(data) {
    return (0, _request.default)({
      url: `/api/manage/manageDevice/stopLive`,
      method: 'post',
      data
    });
  }
};