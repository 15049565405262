"use strict";

var _interopRequireDefault = require("/var/jenkins_home/node_modules_list/test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadView = exports.filterAsyncRouter = exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _routers = require("@/router/routers");
var _index = _interopRequireDefault(require("@/layout/index"));
var _ParentView = _interopRequireDefault(require("@/components/ParentView"));
const permission = {
  state: {
    routers: _routers.constantRouterMap,
    addRouters: [],
    sidebarRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers;
      state.routers = _routers.constantRouterMap.concat(routers);
    },
    SET_SIDEBAR_ROUTERS: (state, routers) => {
      state.sidebarRouters = _routers.constantRouterMap.concat(routers);
    }
  },
  actions: {
    GenerateRoutes({
      commit
    }, asyncRouter) {
      commit('SET_ROUTERS', asyncRouter);
    },
    SetSidebarRouters({
      commit
    }, sidebarRouter) {
      commit('SET_SIDEBAR_ROUTERS', sidebarRouter);
    }
  }
};
const filterAsyncRouter = (routers, isRewrite = false) => {
  // 遍历后台传来的路由字符串，转换为组件对象
  return routers.filter(router => {
    if (isRewrite && router.children) {
      router.children = filterChildren(router.children);
    }
    if (router.component) {
      if (router.component === 'Layout') {
        // Layout组件特殊处理
        router.component = _index.default;
      } else if (router.component === 'ParentView') {
        router.component = _ParentView.default;
      } else {
        const component = router.component;
        router.component = loadView(component);
      }
    }
    if (router.children && router.children.length) {
      router.children = filterAsyncRouter(router.children, router, isRewrite);
    }
    return true;
  });
};
exports.filterAsyncRouter = filterAsyncRouter;
function filterChildren(childrenMap) {
  var children = [];
  childrenMap.forEach((el, index) => {
    if (el.children && el.children.length) {
      if (el.component === 'ParentView') {
        el.children.forEach(c => {
          c.path = el.path + '/' + c.path;
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c));
            return;
          }
          children.push(c);
        });
        return;
      }
    }
    children = children.concat(el);
  });
  return children;
}
const loadView = view => {
  return resolve => require([`@/views/${view}`], resolve);
};
exports.loadView = loadView;
var _default = exports.default = permission;