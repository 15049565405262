"use strict";

var _interopRequireDefault = require("/var/jenkins_home/node_modules_list/test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementVariablesModule = _interopRequireDefault(require("@/assets/styles/element-variables.module.scss"));
var _settings = _interopRequireDefault(require("@/settings"));
const {
  tagsView,
  fixedHeader,
  sidebarLogo,
  uniqueOpened,
  showFooter,
  footerTxt,
  caseNumber
} = _settings.default;
console.log("testst", _elementVariablesModule.default, _elementVariablesModule.default.theme);
const state = {
  theme: _elementVariablesModule.default.theme,
  showSettings: false,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  uniqueOpened: uniqueOpened,
  showFooter: showFooter,
  footerTxt: footerTxt,
  caseNumber: caseNumber
};
const mutations = {
  CHANGE_SETTING: (state, {
    key,
    value
  }) => {
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  }
};
const actions = {
  changeSetting({
    commit
  }, data) {
    commit('CHANGE_SETTING', data);
  }
};
var _default = exports.default = {
  namespaced: true,
  state,
  mutations,
  actions
};