"use strict";

var _interopRequireDefault = require("/var/jenkins_home/node_modules_list/test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = _interopRequireDefault(require("@/store"));
var _default = exports.default = {
  inserted(el, binding) {
    const {
      value
    } = binding;
    const roles = _store.default.getters && _store.default.getters.roles;
    if (value && value instanceof Array) {
      if (value.length > 0) {
        const permissionRoles = value;
        const hasPermission = roles.some(role => {
          return permissionRoles.includes(role);
        });
        if (!hasPermission) {
          el.parentNode && el.parentNode.removeChild(el);
        }
      }
    } else {
      throw new Error(`使用方式： v-permission="['admin','editor']"`);
    }
  }
};