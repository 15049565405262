"use strict";

var _interopRequireDefault = require("/var/jenkins_home/node_modules_list/test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _Logo = _interopRequireDefault(require("./Logo"));
var _SidebarItem = _interopRequireDefault(require("./SidebarItem"));
var _variablesModule = _interopRequireDefault(require("@/assets/styles/variables.module.scss"));
var _default = exports.default = {
  components: {
    SidebarItem: _SidebarItem.default,
    Logo: _Logo.default
  },
  computed: {
    ...(0, _vuex.mapGetters)(['sidebarRouters', 'sidebar']),
    activeMenu() {
      const route = this.$route;
      const {
        meta,
        path
      } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return _variablesModule.default;
    },
    isCollapse() {
      return !this.sidebar.opened;
    }
  }
};