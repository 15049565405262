module.exports = {
  /**
   * @description 网站标题
   */
  title: '无人机管理平台',
  /**
   * @description 是否显示 tagsView
   */
  tagsView: false,
  /**
   * @description 固定头部
   */
  fixedHeader: true,
  /**
   * @description 记住密码状态下的token在Cookie中存储的天数，默认1天
   */
  tokenCookieExpires: 1,
  /**
   * @description 记住密码状态下的密码在Cookie中存储的天数，默认1天s
   */
  passCookieExpires: 1,
  /**
   * @description 是否只保持一个子菜单的展开
   */
  uniqueOpened: true,
  /**
   * @description token key
   */
  TokenKey: '-TOEKN',
  /**
   * @description 请求超时时间，毫秒（默认2分钟）
   */
  timeout: 1200000,
  /**
   * @description 是否显示logo
   */
  sidebarLogo: true,
  /**
   * 是否显示设置的底部信息
   */
  showFooter: false,
  /**
   * 底部文字，支持html语法
   */
  // footerTxt: '© 2019 Tui Mi <a href="http://www.apache.org/licenses/LICENSE-2.0" target="_blank">Apache License 2.0</a>',
  /**
   * 备案号
   */
  // caseNumber: '浙ICP备18005431号',
  /**
   * 高德地图key
   */
  mapKey: '',
  /**
   * 中心点坐标
   */
  centerPoint: [123.438686, 41.750986],
  /**
   * 地图级别
   */
  zoom: 17
  /**
   * 
   */
  //  windowHeight:window.innerHeight
  // windowHeight: document.documentElement.clientHeight || document.body.clientHeight
};