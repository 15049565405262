"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setUserInfo = exports.logOut = exports.default = void 0;
var _login = require("@/api/login");
var _auth = require("@/utils/auth");
const user = {
  state: {
    token: (0, _auth.getToken)(),
    user: {},
    roles: [],
    // 第一次加载菜单时用到
    loadMenus: false
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_LOAD_MENUS: (state, loadMenus) => {
      state.loadMenus = loadMenus;
    }
  },
  actions: {
    // 登录
    Login({
      commit
    }, userInfo) {
      const rememberMe = userInfo.rememberMe;
      return new Promise((resolve, reject) => {
        (0, _login.login)(userInfo.username, userInfo.password, userInfo.code, userInfo.uuid).then(res => {
          (0, _auth.setToken)(res.token, rememberMe);
          commit('SET_TOKEN', res.token);
          setUserInfo(res.user, commit);
          // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js
          commit('SET_LOAD_MENUS', true);
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    // 获取用户信息
    GetInfo({
      commit
    }) {
      return new Promise((resolve, reject) => {
        (0, _login.getInfo)().then(res => {
          setUserInfo(res, commit);
          resolve(res);
        }).catch(error => {
          reject(error);
        });
      });
    },
    // 登出
    LogOut({
      commit
    }) {
      return new Promise((resolve, reject) => {
        (0, _login.logout)().then(res => {
          logOut(commit);
          resolve();
        }).catch(error => {
          logOut(commit);
          reject(error);
        });
      });
    },
    updateLoadMenus({
      commit
    }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOAD_MENUS', false);
      });
    }
  }
};
const logOut = commit => {
  commit('SET_TOKEN', '');
  commit('SET_ROLES', []);
  (0, _auth.removeToken)();
};
exports.logOut = logOut;
const setUserInfo = (res, commit) => {
  // 如果没有任何权限，则赋予一个默认的权限，避免请求死循环
  if (res.roles.length === 0) {
    commit('SET_ROLES', ['ROLE_SYSTEM_DEFAULT']);
  } else {
    commit('SET_ROLES', res.roles);
  }
  commit('SET_USER', res.user);
};
exports.setUserInfo = setUserInfo;
var _default = exports.default = user;