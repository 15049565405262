"use strict";

var _interopRequireDefault = require("/var/jenkins_home/node_modules_list/test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const baseUrl = '/api/hangar';
var _default = exports.default = {
  // 分页查询机库列表数据
  queryListByPage(pageNum, pageSize, data) {
    return (0, _request.default)({
      url: `${baseUrl}/queryPageList/${pageNum}/${pageSize}`,
      method: 'post',
      data: data
    });
  },
  // 新增
  addOrUpHangar(data) {
    return (0, _request.default)({
      url: `${baseUrl}/addOrUpHangar`,
      method: 'post',
      data
    });
  },
  // 删除
  deleteById(id) {
    return (0, _request.default)({
      url: `${baseUrl}/${id}`,
      method: 'delete'
    });
  },
  // 查询所有机库
  queryHangarAll() {
    return (0, _request.default)({
      url: `${baseUrl}`,
      method: 'get'
    });
  },
  queryHangarById(hangarId) {
    return (0, _request.default)({
      url: `${baseUrl}/${hangarId}`,
      method: 'get'
    });
  },
  // 根据id查询
  queryById(hangarId) {
    return (0, _request.default)({
      url: `${baseUrl}/queryById/${hangarId}`,
      method: 'get'
    });
  },
  // 机库控制
  hangarControl(data) {
    return (0, _request.default)({
      url: `${baseUrl}/hangarControl`,
      method: 'post',
      data
    });
  },
  // 机库舱外视频
  queryAirportOutLiveUrl(data) {
    return (0, _request.default)({
      url: `${baseUrl}/queryAirportOutLiveUrl`,
      method: 'post',
      data
    });
  },
  // 机库实时数据
  queryDockOSDBySN(data) {
    return (0, _request.default)({
      url: `${baseUrl}/queryDockOSDBySN`,
      method: 'post',
      data
    });
  },
  // 机库累计数据
  queryTotalData(data) {
    return (0, _request.default)({
      url: `${baseUrl}/queryTotalData`,
      method: 'post',
      data
    });
  },
  // 查询所有机库以及在线状态
  queryHangarAllWithOnline() {
    return (0, _request.default)({
      url: `${baseUrl}/queryHangarAllWithOnline`,
      method: 'get'
    });
  }
};