"use strict";

var _interopRequireDefault = require("/var/jenkins_home/node_modules_list/test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _RightPanel = _interopRequireDefault(require("@/components/RightPanel"));
var _components = require("./components");
var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));
var _vuex = require("vuex");
var _ThemePicker = _interopRequireDefault(require("@/components/ThemePicker"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _default = exports.default = {
  name: 'Layout',
  components: {
    AppMain: _components.AppMain,
    Navbar: _components.Navbar,
    RightPanel: _RightPanel.default,
    Settings: _components.Settings,
    Sidebar: _components.Sidebar,
    TagsView: _components.TagsView,
    Theme: _ThemePicker.default
  },
  mixins: [_ResizeHandler.default],
  computed: {
    ...(0, _vuex.mapState)({
      sidebar: state => state.app.sidebar,
      device: state => state.app.device,
      showSettings: state => state.settings.showSettings,
      needTagsView: state => state.settings.tagsView,
      fixedHeader: state => state.settings.fixedHeader
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    }
  },
  mounted() {
    if (_jsCookie.default.get('theme')) {
      this.$refs.theme.theme = _jsCookie.default.get('theme');
      this.$store.dispatch('settings/changeSetting', {
        key: 'theme',
        value: _jsCookie.default.get('theme')
      });
    }
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    }
  }
};